import React, { Component } from 'react';
import HelmetEx from '../../components/helmet-ex';
import Layout from '../../components/layout';
import ServiceRow from '../../components/service-row';
import FabricImage from '../../images/photos/products/fabrics.png';
import ShadesImage from '../../images/photos/products/shades.png';
import DrapesImage from '../../images/photos/products/drapes.png';
import SubmenuList from '../../components/submenu-list';
import Config from '../../config';

class HomeDecor extends Component {
    render() {
        const route = Config.Services.ProductRoutes.FeaturedProjects;
        return (
            <Layout>
                <div className="home-decor-page">
                    <HelmetEx>
                        <div metadata="title">Home Decor | Fabrics | Roman, Austrian, Balloon Shades | Elmwood Park, NJ</div>
                        <div metadata="keywords">fabrics,roman,austrian,balloon,shades,bergen,shades,county,nj</div>
                        <div metadata="description">
                            Realize your vision for a beautiful home with our variety of fine
                            decorative fabrics for draperies, valances, bedspreads, pillows,
                            upholstery and more.
                        </div>
                    </HelmetEx>
                    <section className="header">
                        <div className="container">
                            <div className="content">
                                <div className="text">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h1>HOME DECOR</h1>
                                            {/* <h3></h3> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="navigation-section">
                        <div className="container">
                            <div className="row">
                                <SubmenuList activePath={route} />
                            </div>
                        </div>
                    </section>
                    <section className="below-navigation-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow flip title="FABRICS" src={FabricImage}>
                                        <p>
                                            Realize your vision for a beautiful home with our variety of fine
                                            decorative fabrics for draperies, valances, bedspreads, pillows,
                                            upholstery and more.
                                        </p>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow title="SHADES" src={ShadesImage}>
                                        <ul className="pl-0 bullets">
                                            <li>Roman Shades</li>
                                            <li>Austrian Shades</li>
                                            <li>Balloon Shades</li>
                                        </ul>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <ServiceRow flip title="DRAPES" src={DrapesImage}>
                                        <ul className="pl-0 bullets">
                                            <li>Stage Drapes</li>
                                        </ul>
                                    </ServiceRow>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export default HomeDecor;